#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#header .header1 .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2rem;
    height: 100%;
}

#header .header1 .container .logo {
    position: absolute;
    max-width: 10%;
    top: 0;
}

#header .header1 .container .title {
    font-size: 58px;
    text-align: left;
    width: 50%;
}

#header .header1 .container .text {
    font-size: 30px;
    width: 50%;
}

#header .header1 .container #ctaWpp {
    margin-right: auto;
    width: 30%;
    margin-bottom: 1rem;
}

#header .header1 .container #ctaWpp a figure {
    width: 7%;
}

#header .mobile {
    display: none;
}

#header .header2 .container {
    display: flex;
    align-items: center;
    gap: 2rem;
}

#header .header2 .container .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .left .title {
    text-align: left;
}

#header .header2 .container .left .mobile {
    display: none;
}

#header .header3 {
    background: url("../../assets/header3.png") no-repeat center center;
    background-size: cover;
}

#header .header3 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}

#header .header3 .container .text {
    margin-top: -1rem;
}

#header .header3 .container .carousel {
    width: 100%;
}

#header .header3 .container .ctas {
    display: flex;
    gap: 1rem;
    width: 50%;
    margin: 0 auto;
}

#header .header3 .container .ctas figure {
    margin-top: .15rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 70vh;
    }

    #header .header1 .container {
        justify-content: center;
    }

    #header .header1 .container .logo {
        max-width: 20%;
    }

    #header .header1 .container .title {
        font-size: 45px;
        padding-bottom: 2rem;
    }

    #header .header1 .container .text {
        display: none;
    }

    #header .header1 .container #ctaWpp {
        display: none;
    }

    #header .mobile {
        display: block;
    }

    #header .mobile .container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        text-align: center;
    }

    #header .mobile .container #ctaWpp {
        margin: 0 auto;
    }

    #header .header2 .container {
        flex-direction: column;
    }

    #header .header2 .container .left .title {
        text-align: center;
    }

    #header .header2 .container .left .mobile {
        display: block;
    }

    #header .header2 .container .left .mobile img {
        width: 70%;
    }

    #header .header2 .container .left .text {
        text-align: center;
    }

    #header .header2 .container .left #ctaWpp {
        margin: 0 auto;
    }

    #header .header2 .container .right {
        display: none;
    }

    #header .header3 .container .ctas {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    #header .header3 .container .ctas figure {
        margin-top: .15rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 65vh;
    }

    #header .header1 .container .logo {
        max-width: 25%;
    }

    #header .header1 .container .title {
        font-size: 30px;
    }

    #header .mobile .container #ctaWpp {
        width: 75%;
    }

    #header .header2 .container #ctaWpp {
        width: 75%;
    }

    #header .header3 .container .ctas #ctaWpp {
        width: 75%;
    }

    #header .header3 .container .ctas #ctaTel {
        width: 75%;
    }
}