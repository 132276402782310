#about .about1 .container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

#about .about1 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

#about .about1 .container .left .title {
  text-align: left;
}

#about .about1 .container .left .mobile {
  display: none;
}

#about .about1 .container .right {
  width: 90%;
}

#about .about2 {
  background-color: #E0E4EB;
}

#about .about2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

#about .about2 .container .text {
  margin-top: -1rem;
}

#about .about2 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 1rem;
}

#about .about2 .container .gallery .item {
  background-color: white;
  padding: 2rem 1rem;
  border-radius: 5px;
  border-bottom: 8px solid #C60910;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .gallery .item .top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
  align-items: center;
}

#about .about2 .container .gallery .item .top figure {
  max-width: 12%;
}

#about .about2 .container .gallery .item .top .text {
  color: #C60910;
  font-weight: bold;
  font-size: 30px;
}

#about .about2 .container .gallery .text {
  text-align: left;
  font-size: 20px;
}

#about .about2 .container .ctas {
  display: flex;
  gap: 1rem;
  width: 50%;
  margin: 0 auto;
}

#about .about2 .container .ctas figure {
  margin-top: .15rem;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about3 .container .middle img {
  width: 70%;
}

#about .about3 .container #ctaWpp {
  margin: 0 auto;
  width: 30%;
}

#about .about4 {
  background-color: #F3F5F9;
}

#about .about4 .container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

#about .about4 .container figure img {
  width: 25%;
}

#about .about5 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

#about .about5 .container .ctas {
  display: flex;
  gap: 1rem;
  width: 50%;
  margin: 0 auto;
}

#about .about5 .container .ctas figure {
  margin-top: .15rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container {
    flex-direction: column;
    text-align: center;
  }

  #about .about1 .container .left .title {
    text-align: center;
  }

  #about .about1 .container .left .mobile {
    display: block;
  }

  #about .about1 .container .left .mobile img {
    width: 60%;
  }

  #about .about1 .container .left #ctaWpp {
    margin: 0 auto;
  }

  #about .about1 .container .right {
    display: none;
  }

  #about .about2 .container .gallery {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
  }

  #about .about2 .container .gallery .item {
    max-width: 60%;
    margin: 0 auto;
  }

  #about .about2 .container .gallery .item .top .text {
    font-size: 25px;
  }

  #about .about2 .container .gallery .text {
    font-size: 17px;
  }

  #about .about2 .container .ctas {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  #about .about3 .container #ctaWpp {
    width: 50%;
  }

  #about .about4 .container figure img {
    width: 30%;
  }

  #about .about5 .container .ctas {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .left #ctaWpp {
    width: 75%;
  }

  #about .about2 .container .gallery .item {
    max-width: 90%;
  }

  #about .about2 .container .gallery .item .top .text {
    font-size: 20px;
  }

  #about .about2 .container .gallery .text {
    font-size: 14px;
  }

  #about .about2 .container .ctas #ctaWpp {
    width: 75%;
  }

  #about .about2 .container .ctas #ctaTel {
    width: 75%;
  }

  #about .about3 .container .middle img {
    width: 100%;
  }

  #about .about3 .container #ctaWpp {
    width: 75%;
  }

  #about .about4 .container figure img {
    width: 50%;
  }

  #about .about5 .container .ctas #ctaWpp {
    width: 75%;
  }

  #about .about5 .container .ctas #ctaTel {
    width: 75%;
  }
}